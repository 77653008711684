<nav class="navbar" id="menu">
    <a class="navbar-brand"><img src="../assets/dotwise250.png"></a>
    <ul class="nav navbar-nav navbar-right">
      <li><a href="https://www.instagram.com/dot.wise/" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="40" height="40"
        viewBox="0 0 226 226"
        style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,226v-226h226v226z" fill="none"></path><g fill="#ffffff"><path d="M72.32,13.56c-32.39717,0 -58.76,26.36283 -58.76,58.76v81.36c0,32.39717 26.36283,58.76 58.76,58.76h81.36c32.39717,0 58.76,-26.36283 58.76,-58.76v-81.36c0,-32.39717 -26.36283,-58.76 -58.76,-58.76zM72.32,22.6h81.36c27.51091,0 49.72,22.20909 49.72,49.72v81.36c0,27.51091 -22.20909,49.72 -49.72,49.72h-81.36c-27.51091,0 -49.72,-22.20909 -49.72,-49.72v-81.36c0,-27.51091 22.20909,-49.72 49.72,-49.72zM167.24,49.72c-4.99265,0 -9.04,4.04735 -9.04,9.04c0,4.99265 4.04735,9.04 9.04,9.04c4.99265,0 9.04,-4.04735 9.04,-9.04c0,-4.99265 -4.04735,-9.04 -9.04,-9.04zM113,63.28c-27.40606,0 -49.72,22.31394 -49.72,49.72c0,27.40606 22.31394,49.72 49.72,49.72c27.40606,0 49.72,-22.31394 49.72,-49.72c0,-27.40606 -22.31394,-49.72 -49.72,-49.72zM113,72.32c22.52048,0 40.68,18.15952 40.68,40.68c0,22.52048 -18.15952,40.68 -40.68,40.68c-22.52048,0 -40.68,-18.15952 -40.68,-40.68c0,-22.52048 18.15952,-40.68 40.68,-40.68z"></path></g></g></svg>
      </a></li>
    </ul>
</nav>

<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="d-block w-100" src="../assets/dotwise.png" alt="First slide">
        <div class="carousel-caption d-none d-md-block">
            <h5>Seja bem vindo a dotwise!</h5>
            <p>Sua empresa de soluções tecnológicas.</p>
          </div>
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="../assets/code.png" alt="Second slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="../assets/server.png" alt="Third slide">
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
</div>

<div class="container-fluid" id="bloco-empresa">
  <div class="container conteudo">
    <h2 class="mb-5">EMPRESA</h2>
    <p>A dotwise é uma empresa de tecnologia voltada para o ramo de serviço e venda de equipamentos. Fundada
      em Julho de 2020 no estado do Espírito Santo, por um Engenheiro de Computação e um Analista de Tecnologia
      da Informação, tendo em seu propósito a satisfação do seus clientes, entregando serviços e produtos de
      qualidade.
    </p>
  </div>
</div>

<div class="container-fluid" id="bloco-servicos">
  <div class="container conteudo2">
    <div class="row">
      <div class="col-sm">
        <h4 class="mb-5">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-file-code" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4 1h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H4z"/>
            <path fill-rule="evenodd" d="M8.646 5.646a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L10.293 8 8.646 6.354a.5.5 0 0 1 0-.708zm-1.292 0a.5.5 0 0 0-.708 0l-2 2a.5.5 0 0 0 0 .708l2 2a.5.5 0 0 0 .708-.708L5.707 8l1.647-1.646a.5.5 0 0 0 0-.708z"/>
          </svg><br><br>
          DESENVOLVIMENTO
        </h4>
        <p>Trabalhamos a cada dia para propor soluções que realmente otimizarão seu negócio. Monte um site para sua empresa com as linguagens e ferramentas mais modernas do mercado.</p>    
      </div>
      <div class="col-sm" id="col-infra">
        <h4 class="mb-5">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-wifi" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.385 6.115a.485.485 0 0 0-.048-.736A12.443 12.443 0 0 0 8 3 12.44 12.44 0 0 0 .663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.717 2.164.204.148.489.13.668-.049z"/>
            <path d="M13.229 8.271c.216-.216.194-.578-.063-.745A9.456 9.456 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.577 1.336c.205.132.48.108.652-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.472 6.472 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.408.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .708 0l.707-.707z"/>
          </svg><br><br>
          INFRAESTRUTURA
        </h4>
        <p>Ataques hackers crescem à medida que pandemia da COVID-19 se alastra. Entre em contato e deixe seu empreendimento realmente seguro de uma vez!</p>
      </div>
      <div class="col-sm">
        <h4 class="mb-5">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-hammer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.812 1.952a.5.5 0 0 1-.312.89c-1.671 0-2.852.596-3.616 1.185L4.857 5.073V6.21a.5.5 0 0 1-.146.354L3.425 7.853a.5.5 0 0 1-.708 0L.146 5.274a.5.5 0 0 1 0-.706l1.286-1.29a.5.5 0 0 1 .354-.146H2.84C4.505 1.228 6.216.862 7.557 1.04a5.009 5.009 0 0 1 2.077.782l.178.129z"/>
            <path fill-rule="evenodd" d="M6.012 3.5a.5.5 0 0 1 .359.165l9.146 8.646A.5.5 0 0 1 15.5 13L14 14.5a.5.5 0 0 1-.756-.056L4.598 5.297a.5.5 0 0 1 .048-.65l1-1a.5.5 0 0 1 .366-.147z"/>
          </svg><br><br>          
          MANUTENÇÃO
        </h4>
        <p>Manutenção preventiva, reparos e suporte técnico. Problemas no computador ou periféricos? Contate-nos e descubra como podemos te ajudar.</p>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <footer class="col-md-12" id="rodape">
      <p>Rua Luiz Antônio de Almeida, 2014 - CEP: 29640-000 - Santa Leopoldina-ES, Brasil
      <br>contato.dotwise@gmail.com
      <br>(27)999033021
      <br>dotwise</p>
    </footer>
  </div>
</div>
